import { DefaultLayoutFeature, MyPagesLayoutFeature } from '@ori-appshell/layouts';

const WithSendSearchEvents = {
  /** This enables/disables sending search events to analytics, docs: None */
  SendSearchEvents: '3767D0FE-0404-4FC8-A552-4B3F2A4036C7',
};

export const PageFeatures = {
  ...DefaultLayoutFeature,
  ...WithSendSearchEvents,
} as const;

export const MyPagesPageFeatures = {
  ...MyPagesLayoutFeature,
  ...WithSendSearchEvents,
} as const;

export const ProductDetailPageFeatures = {
  ...PageFeatures,
  /** This feature toggle is responsible for enabling offers on pdp, inside appShell only., docs: None */
  ShowOffersOnPdp: '5F6D2451-BACD-445F-A5F2-41A981CA684C',
  /** Should be used to reveal to the user only Offers type 'Buy' (on PDL / PDP), with other types hidden, docs: None */
  ShowOffersTypeBuyOnPdp: 'C9CE100E-F6D3-4217-ABE8-0964B8538E84',
  /** Enable Offers type GET, docs: None */
  ShowOffersTypeGetOnPdp: 'B974F70F-6E13-4E16-8A7B-9A0C0489AAE1',
  /** Enable Offers type SET, docs: None */
  ShowOffersSetsOnPdp: 'A974F70F-6E13-4E16-8A7B-9A0C0489BCB2',
  /** This feature toggle is responsible for enabling medallia quiz on PDP, docs: https://oriflamecosmetics.sharepoint.com/teams/Presentationteam/SitePages/Medallia-questionnaire.aspx */
  EnableMedalliaQuizOnPDP: 'F00EC4CD-D661-46B3-8D68-40506978DE31',
  /** Enable Olapic 2.0, docs: https://oriflamecosmetics.sharepoint.com/teams/Presentationteam/SitePages/Olapic%202.0.aspx */
  Olapic2: 'FE17E0E8-3943-44D9-953C-A9D9228218F9',
  /** Show recommended products on PDP instead of related products (ASMS PDP only), docs: https://dev.azure.com/oriflame/GlobalDev/_wiki/wikis/GlobalDev.wiki/10774/PDP-Recommended-products-carousel */
  EnableRecomendedProductsOnPdp: '6949B120-3A39-467A-BCDD-EFA30488B65F',
  /** Enable personal subscriptions, docs: https://dev.azure.com/oriflame/GlobalDev/_wiki/wikis/GlobalDev.wiki/11422/Personal-subscriptions */
  PersonalSubscriptions: '7D673EF4-D085-44B5-BB1C-8FE57F1286BD',
} as const;
